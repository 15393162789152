import './App.scss';
import Portfolio from './containers/Portfolio';

function App() {
  return (
    <div className="App">
        <div >
          <Portfolio/>
        </div>
    </div>
  );
}

export default App;
